.map__btn {
  position: absolute !important;
  padding: 10px;
  margin: 0px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: 100;
  border: none;
  z-index: 1000;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  background-color: #ffffff;
  font-size: 17px;
}

.map__btn:hover {
  background-color: #d8d8d8;
}

.map__btn:active {
  background-color: #b6b6b6;
}

.map__btn--zoomout {
  border-radius: 0px 0px 3px 3px;
}

.map__btn--zoomin {
  border-radius: 3px 3px 0px 0px;
  z-index: 20;
}

/* STYLE MAPS GOOGLE API */
.gm-style .gm-style-iw-c {
  background-color: transparent !important;
  overflow: visible !important;
  box-shadow: none;
  zoom: 0.95;
}

.gm-style {
  zoom: 1.1;
}

.gm-style-iw-d {
  overflow: visible !important;
}

.gm-style-iw-tc,
.gm-ui-hover-effect {
  display: none !important;
}

.mapCard {
  position: relative;
  z-index: 999;
  display: flex;
  white-space: nowrap;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  filter: drop-shadow(0 2px 2px rgba(41, 41, 41, 0.4));
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  top: 0.6rem;
}

.mapCard--position-left {
  left: -4.2rem;
}

.mapCard--position-right {
  left: 3.9rem;
}

.mapCard__title {
  border-radius: 15px;
  text-align: center;
  font-size: 0.65rem;
}

.mapCard__title--operational {
  padding: 2px 3px;
  background-color: #d9d9d9;
  color: black;
}

.mapCard__title--stop {
  padding: 2px 18px;
  background-color: #f36d31;
  color: white;
}

.mapCard__title--no_communication {
  padding: 2px 2px;
  background-color: #4a4a5f;
  color: white;
}

.mapCard__title--ambiental_stop {
  padding: 2px 5px;
  background-color: #371aa1;
  color: white;
}
