.container__grid__title {
  font-size: 40px !important;
  margin-bottom: 16px !important;
}

.container__grid__content {
  margin-bottom: 24px !important;
}

.home-alternative__container {
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}
.home-alternative__container__grid {
  max-width: 1024px;
  margin: 0 auto;
}

.home-alternative__container__grid-item {
  align-items: center;
  height: 50%;
}
