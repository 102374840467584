.pf-v5-c-login {
  background: url(../../assets/img/bg-a7.svg) no-repeat top right #ecf1f4 !important;
  background-size: auto 100%, contain !important;
}

.pf-v5-c-login__header {
  box-shadow: none;
  text-align: center;
}

.pf-v5-c-login__main-header {
  box-shadow: none;
}

@media (max-width: 576px) {
  .pf-v5-c-login__header img {
    width: 15rem;
  }
  .pf-v5-c-login {
    background: #ecf1f4 !important;
    background-size: auto 100%, contain !important;
  }
}
