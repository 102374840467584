.title-page {
  margin-bottom: 40px !important;
}

.cards {
  width: 60%;
  float: left;
  font-size: 14px !important;
  position: relative;
}

.cards .pf-c-card__body {
  position: relative;
}

.cards .pf-c-card {
  background-color: var(--pf-v5-global--BackgroundColor--light-100);
}

.cards .img-estado {
  position: absolute;
  top: -70px;
  right: 0;
  display: none;
}

.cards p {
  font-size: 14px !important;
  line-height: 17px !important;
}

.mapa {
  width: 40%;
  float: left;
  display: block;
  position: relative;
}

.mapa img {
  width: 96%;
  height: auto;
  position: absolute;
  top: 1rem;
  left: 1rem;
  transition: opacity 0.35ms linear;
}

@media (max-width: 891px) {
  .cards {
    width: 100%;
    margin-bottom: 30px;
  }

  .mapa {
    display: none;
  }

  .cards .img-estado {
    display: block;
  }

  .mapa__display__state {
    display: block;
  }
}
